import {graphql} from "gatsby";
import {useCallback} from "react";
import * as React from "react";
import {SocialProfileJsonLd} from "gatsby-plugin-next-seo";
import Location from "../assets/svg/location.svg";
import Layout from "../components/Layout/layout";
import Container from "../components/Layout/Container";
import Seo from "../components/SEO/SEO";
import Input from "../components/Form/Fields/Input";
import Form from "../components/Form";
import Link from "../components/UI/Link";
import Media from "../components/Common/Media";
import * as contactStyles from "./contact.module.css";

const fields = [
    {
        name: "name",
        render({control, register, error, key}) {
            const rules = {
                minLength: {
                    value: 3,
                    message: "Имя должно быть не короче 2-ух символов",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    name={this.name}
                    placeholder="Как вас зовут?"
                    required="Имя обязательно к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "company",
        render({control, register, error, key}) {
            return (
                <Input
                    register={register}
                    control={control}
                    name={this.name}
                    placeholder="Название вашей компании"
                    required="Название компании обязательно к заполнению"
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "phone",
        render({control, register, error, key}) {
            const rules = {
                pattern: {
                    value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/i,
                    message: "Номер телефона должен соответствовать шаблону +7 (123) 456-78-90",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="tel"
                    name={this.name}
                    placeholder="Ваш номер телефона"
                    required="Номер телефона обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "email",
        render({control, register, error, key}) {
            const rules = {
                pattern: {
                    value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
                    message: "Введен некорректный email адрес",
                },
            };

            return (
                <Input
                    register={register}
                    control={control}
                    type="email"
                    name={this.name}
                    placeholder="Ваш email"
                    required="Email обязателен к заполнению"
                    rules={rules}
                    error={error}
                    key={key}
                />
            );
        },
    },
    {
        name: "message",
        render({control, register, key}) {
            return (
                <Input
                    control={control}
                    register={register}
                    type="textarea"
                    name={this.name}
                    placeholder="Сообщение"
                    key={key}
                />
            );
        },
    },
];

const getHref = (url, type) => {
    if (type === "phone") {
        return `tel:${url.replace(/\s/g, "")}`;
    }

    if (type === "email") {
        return `mailto:${url}`;
    }

    return url;
};

const ContactPage = ({data: {contactPage}}) => {
    const formNote = (
        <p className={contactStyles.contact__formNote}>
            Нажимая на кнопку «Отправить», вы даете{" "}
            <Link to="/privacy" withArrow={false}>
                согласие на обработку персональных данных
            </Link>
        </p>
    );

    const handler = useCallback(async (data) => {
        const requestData = {
            title: "Форма на странице контактов",
            department: "sales",
            fields: [
                {
                    label: "Как вас зовут?",
                    value: window.location.href,
                },
                {
                    label: "Имя",
                    value: data.name,
                },
                {
                    label: "Название вашей компании",
                    value: data.company,
                },
                {
                    label: "Ваш номер телефона",
                    value: data.phone,
                },
                {
                    label: "Ваш email",
                    value: data.email,
                },
                {
                    label: "Ваше сообщение",
                    value: data.message,
                },
            ],
        };
        const response = await fetch(`${process.env.GATSBY_API_URL}/mailer/send`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(requestData),
        });
        const json = await response.json();

        if (!json.success) {
            console.error("Error");

            return;
        }

        window.location.href = "/thanks";
    }, []);

    const {seo, communications, documentations, address} = contactPage;

    return (
        <Layout>
            <Seo seo={seo} />
            <SocialProfileJsonLd
                type="Organization"
                name="ITSM 365"
                url="https://itsm365.com"
                sameAs={["https://www.youtube.com/user/itsm365", "https://vk.com/itsm365"]}
            />
            <Container>
                <h1 className={contactStyles.contact__title}>Наши контакты</h1>
                <div className={contactStyles.contact__container}>
                    <div>
                        <h2 className={contactStyles.contact__subtitle}>Как с нами связаться</h2>
                        {communications.map(({id, title, url, type, icon}) => {
                            return (
                                <div key={id} className={contactStyles.contact__linkWrapper}>
                                    {icon && (
                                        <div className={contactStyles.contact__linkIcon}>
                                            <Media media={{youtubeVideoURL: "", content: icon}} />
                                        </div>
                                    )}
                                    <a
                                        className={`${contactStyles.contact__text} ${contactStyles.contact__link}`}
                                        href={getHref(url, type)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {title}
                                    </a>
                                </div>
                            );
                        })}
                        <h2 className={contactStyles.contact__subtitle}>Документация</h2>
                        {documentations.map(({id, title, url, icon}) => {
                            return (
                                <div key={id} className={contactStyles.contact__linkWrapper}>
                                    {icon && (
                                        <div className={contactStyles.contact__linkIcon}>
                                            <Media media={{youtubeVideoURL: "", content: icon}} />
                                        </div>
                                    )}
                                    <a
                                        className={`${contactStyles.contact__text} ${contactStyles.contact__link}`}
                                        href={url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {title}
                                    </a>
                                </div>
                            );
                        })}
                        <h2 className={contactStyles.contact__subtitle}>Адрес</h2>
                        {address.map(({id, address: addressLine}) => {
                            return (
                                <div key={id} className={contactStyles.contact__linkWrapper}>
                                    <div className={contactStyles.contact__linkIcon}>
                                        <Location />
                                    </div>
                                    <p className={contactStyles.contact__text}>{addressLine}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <p className={contactStyles.contact__text}>
                            Оставьте ваши контактные данные и мы свяжемся с вами в ближайшее время
                        </p>
                        <div className={contactStyles.contact__form}>
                            <Form fields={fields} note={formNote} onSubmit={handler} />
                        </div>
                    </div>
                </div>
            </Container>
        </Layout>
    );
};

export const pageQuery = graphql`
    query {
        contactPage: strapiContact {
            seo {
                title
                description
                openGraph {
                    title
                    description
                    image {
                        url
                    }
                }
            }
            communications {
                id
                title
                type
                url
                icon {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            documentations {
                id
                title
                url
                icon {
                    mime
                    width
                    height
                    localFile {
                        publicURL
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            address {
                id
                address
            }
        }
    }
`;

export default ContactPage;
